<template>
  <nav>
  </nav>
  <router-view/>
  <footer>
    <a href="/">Home</a>
    <a href="/register">Register new player</a>
    <a href="/golfers">Edit Golfers</a>
  </footer>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
footer a {
  margin-right: 16px;
}
</style>
