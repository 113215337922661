<template>
	<div class="container">
		<div class="mb-2" style="text-align: left;">
			<select
				class="form-select"
				:value="tournamentId"
				@change="updateTournament"
				style="display: inline-block; width: auto"
			>
				<option
					v-for="tournament in tournaments"
					:key="tournament.tournamentId"
					:value="tournament.tournamentId"
				>{{ tournament.Name }}</option>
			</select>
		</div>
		<h1>Masters Challenge
			<button class="btn btn-primary" @click="sync">
				<span v-if="!loading">Sync</span>
				<div v-else class="spinner-border spinner-border-sm" role="status">
				</div>
			</button>
		</h1>
	</div>
	<div :class="{
		container: !isMobile
	}">
		<table class="table" style="width: 100%; overflow: auto;"
			:style="{
				display: isMobile ? 'block' : 'table',
				overflow: isMobile ? 'auto': null
			}"
		>
			<thead>
				<tr>
					<th scope="col">Rank</th>
					<th scope="col" style="text-align: left">Name</th>
					<th scope="col">THRU</th>
					<th scope="col">R1</th>
					<th scope="col">R2</th>
					<th scope="col">R3</th>
					<th scope="col">R4</th>
					<th scope="col">Total</th>
					<th scope="col" style="width: 100px">Tiebreaker</th>
				</tr>
			</thead>
			<tbody>
				<template
					v-for="(player) in players"
					:key="player.playerId"
				>
					<tr :class="'table-light'">
						<th>
							<i v-if="tournamentOver && player.rank === 0"
								class="bi-trophy-fill" style="color: gold; font-size: 1.3rem"
							/>
							<i v-else-if="tournamentOver && player.rank === 1"
								class="bi-trophy-fill" style="color: silver; font-size: 1.1rem"
							/>
							<i v-else-if="tournamentOver && player.rank === 2"
								class="bi-award-fill" style="color: #CD7F32; font-size: 1.1rem"
							/>
							<span v-else>{{ player.rank + 1 }}</span>
						</th>
						<th style="text-align: left">{{ player.playerName }}</th>
						<th></th>
						<th><span v-if="player.r1 > 0">+</span>{{ player.r1 }}</th>
						<th><span v-if="player.r2 > 0">+</span>{{ player.r2 }}</th>
						<th><span v-if="player.r3 > 0">+</span>{{ player.r3 }}</th>
						<th><span v-if="player.r4 > 0">+</span>{{ player.r4 }}</th>
						<th><span v-if="player.total > 0">+</span>{{ player.total }}</th>
						<th style="width: 100px">{{ player.tiebreaker }}</th>
					</tr>
					<tr v-for="golfer in player.golfers" :key="golfer.golferId">
						<td style="text-align: right">{{ golfer.category }}</td>
						<td style="text-align: left">
							<img v-if="golfer.countryCode"
								:src="'https://flagcdn.com/' + golfer.countryCode + '.svg'"
								:alt="golfer.country"
								width="27"
								style="margin-right: 8px; border: 1px solid lightgrey"
								:title="golfer.country"
							>
							<span>{{ golfer.golferName }}</span>
						</td>
						<td class="small">
							<i v-if="golfer.isCut" class="bi-scissors" style="font-size: 1.2rem; color: red" />
							<i v-else-if="golferIsDone(golfer)" class="bi-check" style="font-size: 1.2rem; color: green" />
							<span v-else>{{ golfer.through }}</span>
						</td>
						<td class="small">{{ getScore(golfer, 'r1') }}</td>
						<td class="small">{{ getScore(golfer, 'r2') }}</td>
						<td class="small">{{ getScore(golfer, 'r3') }}</td>
						<td class="small">{{ getScore(golfer, 'r4') }}</td>
						<td class="small"><span v-if="getGolferTotal(golfer) > 0">+</span>{{ getGolferTotal(golfer) }}</td>
						<td style="width: 100px"></td>
					</tr>
				</template>
			</tbody>
		</table>
		<div class="position-fixed bottom-0 start-50 translate-middle-x p-3" style="z-index: 11">
			<div
				id="liveToast"
				class="toast hide text-white bg-success"
				style="width: 360px"
				role="alert" aria-live="assertive" aria-atomic="true"
				ref="toast"
			>
				<div class="d-flex">
					<div class="toast-body">
						<span class="me-auto">Golfer scores were succesfully stolen from espn!</span>
					</div>
					<button
						type="button"
						class="btn-close btn-close-white me-2 m-auto"
						data-bs-dismiss="toast"
						aria-label="Close"
					></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'HomeView',
	data: () => ({
		players: [],
		loading: false,
		toast: null,
		windowWidth: 0,
		listenerId: null,
	}),
	computed: {
		isMobile() {
			return this.windowWidth < 1280;
		},
		tournamentOver() {
			return this.players.length > 0 && this.players.every((player) =>
				player.golfers.every((golfer) => this.golferIsDone(golfer)));
		},
		tournaments() {
			console.log(this.$store.state);
			return this.$store.state.tournaments;
		},
		tournamentId() {
			return this.$store.state.tournamentId;
		},
	},
	created() {
		if (this.tournamentId) {
			this.getPlayers(this.tournamentId);
		}
		this.windowWidth = window.innerWidth;
		this.listenerId = window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
	mounted() {
		const el = this.$refs.toast;
		this.toast = new bootstrap.Toast(el, {});
		this.getTournaments();
	},
	watch: {
		tournamentId(id) {
			this.getPlayers(id);
		},
	},
	methods: {
		getTournaments() {
			this.$store.dispatch('getTournaments');
		},
		updateTournament(event) {
			this.$store.commit('setTournament', event.target.value);
		},
		getPlayers(tournamentId) {
			return fetch(`/api/v1/players?tournamentId=${tournamentId}`).then((res) => res.json())
				.then((ret) => {
					const players = ret.slice();
					for (let i = 0; i < players.length; i++) {
						if (i === 0) {
							players[i].rank = 0;
						} else if (players[i].total === players[i - 1].total) {
							players[i].rank = players[i - 1].rank;
						} else {
							players[i].rank = i;
						}
					}
					this.players = players;
				});
		},
		golferIsDone(golfer) {
			return (golfer.r1 === 'E' || (typeof golfer.r1 === 'number'))
				&& (golfer.r2 === 'E' || (typeof golfer.r2 === 'number'))
				&& (golfer.r3 === 'E' || (typeof golfer.r3 === 'number'))
				&& (golfer.r4 === 'E' || (typeof golfer.r4 === 'number'))
				&& golfer.through === null;
		},
		getGolferTotal(golfer) {
			let total = 0;
			if (!Number.isNaN(+golfer.r1)) { total += golfer.r1; }
			if (!Number.isNaN(+golfer.r2)) { total += golfer.r2; }
			if (!Number.isNaN(+golfer.r3)) { total += golfer.r3; }
			if (!Number.isNaN(+golfer.r4)) { total += golfer.r4; }
			return total;
		},
		getScore(golfer, round) {
			const score = golfer[round];
			if (golfer.through === null && !Number.isNaN(+score)) {
				return 72 + score;
			}
			if (golfer.through === null && score === 'E') {
				return 72;
			}
			if (round === 'r1' && (!Number.isNaN(+golfer.r2) || golfer.r2 === 'E')) {
				if (score === 'E') { return 72; }
				return 72 + score;
			}
			if (round === 'r2' && (!Number.isNaN(+golfer.r3) || golfer.r3 === 'E')) {
				if (score === 'E') { return 72; }
				return 72 + score;
			}
			if (round === 'r3' && (!Number.isNaN(+golfer.r4) || golfer.r4 === 'E')) {
				if (score === 'E') { return 72; }
				return 72 + score;
			}
			if (!Number.isNaN(+score) && score > 0) {
				return `+${score}`;
			}
			return score;
		},
		sync() {
			this.loading = true;
			fetch('/api/v1/golfers/scrape')
				.then((res) => {
					if (res.status >= 400) {
						return res.text().then((error) => {
							throw new Error(error);
						});
					}
					return res;
				})
				.then(() => this.getPlayers(this.tournamentId))
				.then(() => {
					this.loading = false;
					this.toast.show();
				})
				.catch((error) => {
					console.error(error);
					this.loading = false;
				});
		},
	},
};
</script>
